.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  /*height: 50px;*/
  /*padding: 20px;*/
  color: white;
}

.App-title {
  /* font-size: 1.5em; */
  font-size: 1.2em;
  font-weight: bolder;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

* {
  box-sizing: border-box;
}
body, html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.StripeElement {
  display: block;
  margin: auto;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.formInput {
  width: 100% !important;
  display: block !important;
  margin: auto !important;
  height: 41.6px !important;
  max-width: 500px !important;
  padding: 10px 14px !important;
  border-width: 0px !important;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  background: white !important;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.priceUpdateButton {
  padding: 10px 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color: lightgray;
  border-radius: 2px;
  padding: 2.5px;
  /* height: 20px;
  line-height: 20px; */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}

.languageSelect-Container {
  margin-bottom: 2rem;
}

.closeModalButton {
  text-align: right;
  font-size: 2rem;
  cursor: pointer;
}
