.header {
  background: white; 
  color: rgb(153, 204, 255);
}

.headerItems {
  display: flex;
  justify-content: space-between;
  
}

.AppTitle {
  /* font-size: 1.5em; */
  font-size: 1.2em;
  font-weight: bolder;
}

.navWrapper {
  position: relative;
  height: 100%;

  margin: 0 auto;
  max-width: 1280px;
  width: 95%;
}